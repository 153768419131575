<template>
  <div class="txt-c">
    <p style="margin-top: 50px;">
      <van-icon name="info" size="48px" />
    </p>
    <p>请在微信中访问链接</p>
  </div>
</template>
<script setup>
</script>
